import { Button, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteAgentModal } from 'components/modals/DeleteAgentModal';
import { ResetAgentMfaModal } from 'components/modals/ResetAgentMfaModal';
import { Column, Table } from 'components/Table';
import { AgentDTO, AgentRole } from 'dtos/agent';
import { useGetAgencyUsers } from 'queries/useAgency';
import React, { useEffect, useMemo, useState } from 'react';
import { isAgentAnAnyAdmin } from 'telivy-selectors';

import { CreateEditAgent } from './CreateEditAgent';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  title: {
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    marginRight: theme.spacing(2),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  edit: {
    paddingTop: theme.spacing(0.025),
    paddingBottom: theme.spacing(0.025),
  },
}));

interface Props {
  agent: AgentDTO;
}

export const AgencyAgentsTable = ({ agent }: Props) => {
  const classes = useStyles();
  const isAgencyAdmin = isAgentAnAnyAdmin(agent);

  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<AgentDTO | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setSelectedUser(undefined);
    setIsOpen(false);
  };

  const { data, isLoading } = useGetAgencyUsers(selectedAccount || '', {
    enabled: Boolean(selectedAccount),
  });

  const allAccounts = useMemo(() => {
    return agent.agency ? [agent.agency, ...(agent.subAgencies || [])] : [];
  }, [agent]);

  useEffect(() => {
    if (!selectedAccount && agent.agency) {
      setSelectedAccount(agent.agency.id);
    }
  }, [agent, selectedAccount, setSelectedAccount]);

  const columns: Array<Column<AgentDTO, null>> = useMemo(() => {
    return [
      {
        title: 'Name',
        render: (a) => `${a.firstName} ${a.lastName}`,
      },
      {
        title: 'E-mail',
        render: (a) => a.email,
      },
      {
        title: 'Role',
        render: (a) => {
          switch (a.role) {
            case AgentRole.AGENCY_ADMIN:
              return 'All Assessments';
            case AgentRole.REGULAR:
              return 'Own Assessments';
            default:
              return 'None';
          }
        },
      },
      {
        title: 'Action',
        render: (row) =>
          isAgencyAdmin && (
            <div className='flex gap-2'>
              <Button className={classes.edit} onClick={() => setSelectedUser(row)}>
                Edit
              </Button>
              {data && (
                <>
                  <DeleteAgentModal
                    agent={row}
                    allAgents={data}
                    account={{
                      allAccounts,
                      selectedAccount,
                    }}
                  />
                  <ResetAgentMfaModal agent={row} />
                </>
              )}
            </div>
          ),
      },
    ];
  }, [isAgencyAdmin, classes.edit, data, allAccounts, selectedAccount]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h1 className={classes.title}>Users</h1>
        {isAgencyAdmin && (
          <Button variant='contained' color='primary' onClick={() => setIsOpen(true)}>
            Add User
          </Button>
        )}
        {isAgencyAdmin && (
          <CreateEditAgent open={isOpen} agent={selectedUser} agencyId={selectedAccount} handleClose={handleClose} />
        )}
      </div>
      {allAccounts.length > 0 && (
        <div>
          <span className={classes.subTitle}>Account: </span>
          <Select
            value={selectedAccount || ''}
            onChange={(el) => {
              setSelectedAccount(el.target.value as string);
            }}
          >
            {allAccounts.map((agency) => (
              <MenuItem key={agency.id} value={agency.id}>
                {agency.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <Table<AgentDTO, null> columns={columns} rowKey={(a) => a.id} data={data} loading={isLoading} />
    </div>
  );
};
