import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import { useToasters } from 'hooks/useToasters';
import { useDeleteApplication } from 'queries/useApplications';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'telivy-constants';

interface Props {
  applicationId: string;
  className?: string;
}

export const DeleteButton: React.FC<Props> = ({ applicationId, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToaster } = useToasters();
  const navigate = useNavigate();

  const { mutate, isLoading } = useDeleteApplication(applicationId, {
    onSuccess: () => {
      navigate(`/${ROUTES.agent.DASHBOARD}`);
      showToaster(`Successfully deleted application and all associated data`);
      setIsModalOpen(false);
    },
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button
        className={className}
        onClick={handleModalOpen}
        variant='outlined'
        color='default'
        startIcon={<TrashIcon />}
      >
        Permanently Delete
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <Typography variant='h3' className='!mb-4'>
            Are you sure?
          </Typography>
          <Typography variant='body1'>
            Deleting this application will also delete all associated data. This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button variant='contained' color='secondary' disabled={isLoading} onClick={() => mutate()}>
            {isLoading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
