import { Button, TextField, Typography } from '@material-ui/core';
import { useCurrentAgent } from 'queries/useAgent';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { clearAuthTokens, setAdminAuthToken } from 'services/auth0-tokens';
import { ROUTES } from 'telivy-constants';

export const AdminView: React.FC = () => {
  const navigate = useNavigate();
  const [secret, setSecret] = useState('');
  const [error, setError] = useState('');
  const [userSub, setUserSub] = useState('auth0|');
  const { refetch } = useCurrentAgent({
    enabled: false,
    retry: false,
    onError: () => {
      setError('Incorrect data.');
      clearAuthTokens(true);
    },
  });

  const handleSubmit = useCallback(() => {
    setError('');
    setAdminAuthToken(secret, userSub);
    refetch();
    navigate(ROUTES.agent.ROOT);
  }, [navigate, refetch, secret, userSub]);

  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-50'>
      <Helmet>
        <title>Admin</title>
      </Helmet>

      <div className='w-96 bg-white p-8 rounded-2xl shadow-lg space-y-6'>
        <Typography variant='h3' className='text-center text-gray-900'>
          Admin Login
        </Typography>

        <div className='space-y-4'>
          <div>
            <TextField
              fullWidth
              label='Secret'
              value={secret}
              variant='outlined'
              type='password'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSecret(e.target.value)}
              className='bg-white'
            />
            <p className='m-0 text-gray-500 text-xs mt-1'>Secret known only to the staff.</p>
          </div>

          <div>
            <TextField
              fullWidth
              value={userSub}
              label='User sub'
              variant='outlined'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserSub(e.target.value)}
              className='bg-white'
            />
            <p className='m-0 text-gray-500 text-xs mt-1'>Should start with &apos;auth0|&apos;.</p>
          </div>
        </div>

        <Button fullWidth color='primary' variant='contained' onClick={handleSubmit} className='!py-3'>
          Submit
        </Button>

        {error && (
          <Typography variant='caption' className='block text-center text-red-500 mt-2'>
            {error}
          </Typography>
        )}
      </div>
    </div>
  );
};
