import { ApplicationDocumentType, SecurityBenchmarkingWeight, SecurityScanStatus, TargetType } from 'dtos/application';
import { DeclinationDocumentType } from 'dtos/declinations';
import { PolicyDocumentType } from 'dtos/policy';
import { CyberQuoteSublimits, QuoteDocumentType } from 'dtos/quotes';
import React from 'react';

type CombinedDocumentType = ApplicationDocumentType | DeclinationDocumentType | PolicyDocumentType | QuoteDocumentType;

export const DOCUMENT_TYPE_HUMANIZED_NAME: { [key in CombinedDocumentType]: string } = {
  [ApplicationDocumentType.TELIVY_COMPLETE_REPORT]: 'Complete Report',
  [ApplicationDocumentType.TELIVY_COMPLETE_REPORT_PDF]: 'Complete Report (PDF)',
  [ApplicationDocumentType.TELIVY_COMPLETE_REPORT_DOCX]: 'Complete Report (DOCX)',
  [ApplicationDocumentType.TELIVY_EXTERNAL_SCAN_REPORT]: 'External Scan Report (PDF)',

  [ApplicationDocumentType.TELIVY_PASSWORDS_REPORT]: 'Passwords Report (CSV)',
  [ApplicationDocumentType.TELIVY_PASSWORDS_REUSE_REPORT]: 'Passwords Reuse Report (CSV)',
  [ApplicationDocumentType.TELIVY_DARK_WEB_REPORT]: 'Dark Web Data Report (CSV)',

  [ApplicationDocumentType.TELIVY_NETWORK_INVENTORY_REPORT]: 'Network Inventory Report (CSV)',
  [ApplicationDocumentType.NODEWARE_EXECUTIVE_REPORT]: 'Internal Vulnerabilities Executive Report (PDF)',
  [ApplicationDocumentType.NODEWARE_NETWORK_REPORT]: 'Internal Vulnerabilities Network Report (PDF)',
  [ApplicationDocumentType.NODEWARE_TOP_VULNERABILITIES_REPORT]: 'Top Internal Vulnerabilities Report (PDF)',
  [ApplicationDocumentType.TELIVY_VULNERABILITIES_REPORT]: 'Vulnerabilities Report (CSV)',

  [ApplicationDocumentType.AUGUMENT_DETAILED_REPORT]: 'Applications Security Detailed Report (CSV)',
  [ApplicationDocumentType.AUGMENTT_OVERVIEW_REPORT]: 'Applications Security Overview Report (CSV)',

  [ApplicationDocumentType.ACTIFILE_SENSITIVE_DOCUMENTS]: 'Data Security Sensitive Documents Report (CSV)',
  [ApplicationDocumentType.ACTIFILE_DETAILED_RISK]: 'Data Security Detailed Risk Report (XLSX)',

  [ApplicationDocumentType.M365_SECURITY_REPORT]: 'Microsoft 365 Security Report (PDF)',
  [ApplicationDocumentType.M365_SECURITY_DATA_REPORT]: 'Microsoft 365 Security Data Report (XLSX)',
  [ApplicationDocumentType.GWS_SECURITY_REPORT]: 'Google Workspace Security Report (PDF)',
  [ApplicationDocumentType.GWS_SECURITY_DATA_REPORT]: 'Google Workspace Security Data Report (XLSX)',

  [ApplicationDocumentType.BENCHMARK]: 'Benchmark',
  [ApplicationDocumentType.RISK_CALCULATOR]: 'Risk calculator',
  [ApplicationDocumentType.SECURITY_REPORT]: 'Security Report',
  [ApplicationDocumentType.EMPLOYEE_LIST]: 'Employee List',
  [ApplicationDocumentType.PII_EXCEL_REPORT_BUNDLE]: 'Data Security Excel Report Bundle',
  [ApplicationDocumentType.AUGUMENT_REPORT]: 'Applications Security Report',

  [ApplicationDocumentType.SAAS_ALERTS_DETAILED_REPORT]: 'SaaS Security Detailed Report',
  [ApplicationDocumentType.SAAS_ALERTS_SUMMARY_REPORT]: 'SaaS Security Summary Report',
  [ApplicationDocumentType.PHISHING_REPORT]: 'Phishing Report',

  [ApplicationDocumentType.TELIVY_ALL_REPORTS_ZIP]: 'All Reports (ZIP)',

  [QuoteDocumentType.QUOTE_LETTER]: 'Quote letter',
  [QuoteDocumentType.PREFILLED_APP]: 'Prefilled application',
  [QuoteDocumentType.SECURITY_REPORT]: 'Security Report',
  [QuoteDocumentType.OTHER]: 'Other',
  [DeclinationDocumentType.PREFILLED_APP]: 'Prefilled application',
  [DeclinationDocumentType.SECURITY_REPORT]: 'Security Report',
  [PolicyDocumentType.SECURITY_REPORT]: 'Security Report',
  [PolicyDocumentType.CARRIER_INVOICE]: 'Carrier invoice',
  [PolicyDocumentType.INVOICE]: 'Invoice',
  [PolicyDocumentType.POLICY]: 'Policy',
  [PolicyDocumentType.SIGNED_APPLICATION]: 'Signed application',
  [PolicyDocumentType.CONFIRMATION_OF_INSURANCE]: 'Confirmation of insurance',
  [PolicyDocumentType.PREFILLED_APP]: 'Prefilled application',
  [PolicyDocumentType.TELIVY_APP]: 'Application',
  [PolicyDocumentType.TELIVY_PROPOSAL]: 'Proposal',
  [PolicyDocumentType.QUOTE_LETTER]: 'Quote letter',
  [PolicyDocumentType.SECURITY_REPORT]: 'Security Report',
  [PolicyDocumentType.OTHER]: 'Other',
};

export const SECURITY_BENCHMARK_WEIGHT_TO_HUMANIZED_NAME: { [key in SecurityBenchmarkingWeight]: string } = {
  [SecurityBenchmarkingWeight.firewall]: 'Firewall',
  [SecurityBenchmarkingWeight.antiVirus]: 'Anti-virus Software',
  [SecurityBenchmarkingWeight.backup]: 'Backup',
  [SecurityBenchmarkingWeight.recovery]: 'Recovery',
  [SecurityBenchmarkingWeight.mfa]: 'Multi-Factor Authentication',
  [SecurityBenchmarkingWeight.encryption]: 'Encryption',
  [SecurityBenchmarkingWeight.patching]: 'Patching Critical Software Procedures',
  [SecurityBenchmarkingWeight.continuity]: 'Business Continuity & Disaster Recovery Plans',
  [SecurityBenchmarkingWeight.mediaContent]: 'Media Content Review',
  [SecurityBenchmarkingWeight.vendorSecurityControl]: 'Vendor Security Controls',
  [SecurityBenchmarkingWeight.foundTransferControl]: 'Funds Transfer Controls',
  [SecurityBenchmarkingWeight.securityTraining]: 'Employee Training',
};

// Quote basic info + sublimits
interface QuoteBasicInfo {
  retention: string;
  annualCost: string;
  aggLimit: string;
  premium: string;
}

export type QuoteBasicInfoMap = {
  [Property in keyof QuoteBasicInfo]: {
    title: string;
    description: string | JSX.Element;
  };
};

export const QUOTE_BASIC_INFO_MAP: QuoteBasicInfoMap = {
  retention: { title: 'Retention', description: <>The deductible when a claim is made.</> },
  annualCost: { title: 'Annual Policy Cost', description: <>Cost of purchasing the policy annually.</> },
  aggLimit: { title: 'Aggregate Limit', description: <>The maximum coverage offered for a policy.</> },
  premium: { title: 'Premium', description: <>Premium of the policy annually.</> },
};

export type QuoteSublimitsMap = {
  [Property in keyof CyberQuoteSublimits]: {
    title: string;
    description: string | JSX.Element;
  };
};

export const QUOTE_SUBLIMITS_MAP: QuoteSublimitsMap = {
  networkAndInformationSecurityLiability: {
    title: 'Network and Information Security Liability',
    description: (
      <>
        Refers to a limit of liability available for defense and settlement of claims made against the insured arising
        from a breach in network or information security.
      </>
    ),
  },
  regulatoryDefenseAndPenalties: {
    title: 'Regulatory Defense & Penalties',
    description: (
      <>
        Refers to a limit of liability available for defense and settlement of claims made against the insured for a
        defined regulatory proceeding in the course of a breach. May include coverage for fines and penalties when
        insurable.
      </>
    ),
  },
  multimediaContentLiability: {
    title: 'Multimedia Content Liability',
    description: (
      <>
        Refers to a limit of liability available for defense and settlement of claims made against the insured for what
        they may publish electronically. Certain limitations to this coverage may exist for the type of digital media or
        the intent of such publication.
      </>
    ),
  },
  pciFinesAndAssessments: {
    title: 'PCI Fines & Assessments',
    description: (
      <>
        Refers to a limit of liability available for the fines and penalties administered against the insured for
        PCI/DSS breaches or failures to follow requirements. Policy may or may not provide extra expenses for the
        insured to recertify infrastructure.
      </>
    ),
  },
  breachResponseCostsAndServices: {
    title: 'Breach Response Costs & Services',
    description: (
      <>
        Refers to a limit of liability that can be used for 24/7 access to incident response experts that can help with
        initial assessment and response and strategy. Your policy may require a specific firm to be used or offer a
        reduction of costs for using a panel firm.
      </>
    ),
  },
  forensicAndLegalExpenses: {
    title: 'Forensic & Legal Expenses',
    description: (
      <>
        Often in combination with <b>Breach Response Costs</b> is a limit to provide for forensic specialists to
        determine cause and extent of a breach.
      </>
    ),
  },
  crisisManagementAndPublicRelations: {
    title: 'Crisis Management & Public Relations',
    description: (
      <>
        Refers to a limit of liability to hire public relations experts that can be used to mitigate negative press or
        impending negative press.
      </>
    ),
  },
  reputationalHarmLoss: {
    title: 'Reputational Harm Loss',
    description: (
      <>
        Refers to a limit of liability that essentially applies to the insured&apos;s decrease in net income (adjusted
        according to the policy definitions) due to an adverse publication, on certain mediums, that decreases the
        confidence in the insured&apos;s network.
      </>
    ),
  },
  creditIdentityMonitoringServices: {
    title: 'Credit/Identity Monitoring Services',
    description: (
      <>Would cover the costs to the insured to provide their customers with services to protect forms of PII.</>
    ),
  },
  cyberExtortionExpensesAndFees: {
    title: 'Cyber Extortion Expenses and Fees',
    description: (
      <>
        Is often combined with Ransom Payments and refers to a limit of liability available to pay the amounts demanded
        when the insured is breached and sensitive content is being threatened to be released.
      </>
    ),
  },
  ransomPayments: {
    title: 'Ransom Payments',
    description: (
      <>
        Is often combined with Cyber Extortion and refers to a limit of liability available to pay the ransom when the
        insured&apos;s data may be encrypted or otherwise unusable without paying a ransom.
      </>
    ),
  },
  businessInterruptionContingent3rdPartyComputerSystems: {
    title: 'Business interruptions-  Contingent (3rd party) Computer Systems',
    description: (
      <>
        Refers to a limit of liability that reimburses the insured for a decrease in net income (adjusted based on
        policy language) from operational inefficiencies due to a breach (and most often-also a System Failure - check
        policy language) of a service provider. Coverage typically includes defined extra expenses that are unique and
        extraordinary arising from the breach.
      </>
    ),
  },
  businessInterruptionDirectSystemsBreach: {
    title: 'Business Interruption - Direct Systems Breach',
    description: (
      <>
        Refers to a limit of liability that reimburses the insured for a decrease in net income (adjusted based on
        policy language) from operational inefficiencies due to a breach of the insured. Coverage typically includes
        defined extra expenses that are unique and extraordinary arising from the breach.
      </>
    ),
  },
  businessInterruptionDirectSystemFailure: {
    title: 'Business Interruption - Direct System Failure',
    description: (
      <>
        Refers to a limit of liability that reimburses the insured for a decrease in net income (adjusted based on
        policy language) from operational inefficiencies due to a System Failure of the insured. Coverage typically
        includes defined extra expenses that are unique and extraordinary arising from the failure.
      </>
    ),
  },
  digitalAssetRestoration: {
    title: 'Digital Asset Restoration',
    description: (
      <>
        Refers to a limit of liability that can be used to restore or recreate data that has been corrupted in a breach.
      </>
    ),
  },
  fundsTransferFraudSystemsBreach: {
    title: 'Funds Transfer Fraud - Systems Breach',
    description: (
      <>
        Refers to a limit of liability that reimburses the insured for amounts of money that is fraudulently transferred
        due to a breach. Some policies may combine this coverage with Social Engineering Funds Transfer Fraud.
      </>
    ),
  },
  fundsTransferFraudSocialEngineering: {
    title: 'Funds Transfer Fraud - Social Engineering',
    description: (
      <>
        Refers to a limit of liability that reimburses the insured for amounts of money that the insured sends due to
        fraudulent instructions that are perceived to be genuine. This section may also provide for &apos;reverse social
        engineering&apos; when your partners think they are dealing with you, but are genuinely tricked into dealing
        with a hacker.
      </>
    ),
  },
  reverseSocialEngineeringInvoiceManipulation: {
    title: 'Reverse Social Engineering - Invoice Manipulation',
    description: (
      <>
        Refers to a limit of liability that reimburses the insured for amounts of money that a vendor or third party
        sends due to fraudulent instructions that are perceived to be genuine and sent by you.
      </>
    ),
  },
  hardwareReplacementCostsBricking: {
    title: 'Hardware Replacement Costs - Bricking',
    description: (
      <>
        Refers to a limit of liability that can reimburse the insured for replacing hardware that is determined not to
        be operable at pre-breach efficiencies. This is commonly referred to as &apos;Bricking&apos; coverage. Certain
        limitations may apply from our carriers; like the replacement must be to avoid future events rather than get the
        insured to pre-breach levels, or the event must not arise from unapproved software or content downloaded by an
        insured.
      </>
    ),
  },
  computerSystemsInstallationServicesCosts: {
    title: 'Computer Systems Installation Services Costs',
    description: (
      <>
        Usually contained within <b>Hardware Replacement Costs</b>, refers to a limit of liability used to install
        hardware that no longer performs at pre-breach levels or lacks integrity.
      </>
    ),
  },
  digitalServicesFraudCryptojacking: {
    title: 'Digital Services Fraud - Cryptojacking',
    description: (
      <>
        Refers to a limit of liability that can reimburse the insured for an increase in scheduled subscription expenses
        arising from fraudulent use. Other policies may specifically refer to this coverage as
        &apos;cryptojacking&apos;. This coverage is often limited to telecom or VoIP.
      </>
    ),
  },
  utilitiesExpenseDueToCryptojacking: {
    title: 'Utilities expense due to cryptojacking',
    description: (
      <>
        Refers to a limit of liability that can reimburse the insured for an increase in utilities expenses arising from
        fraudulent use due to an Event. Other policies may specifically refer to this coverage as
        &apos;cryptojacking&apos;.
      </>
    ),
  },
};

export type QuoteInfoMap = QuoteBasicInfoMap & QuoteSublimitsMap;

export const QUOTE_INFO_MAP: QuoteInfoMap = {
  ...QUOTE_BASIC_INFO_MAP,
  ...QUOTE_SUBLIMITS_MAP,
};

export const mapScanningStatus = (status: SecurityScanStatus | undefined) => {
  switch (status) {
    case SecurityScanStatus.NEW:
    case SecurityScanStatus.SCANNING:
      return 'In Progress';
      break;

    case SecurityScanStatus.COMPLETED:
      return 'Complete';
      break;

    default:
      return 'Not Started';
      break;
  }
};

export const mapTargetType = (targetType: TargetType | undefined) => {
  switch (targetType) {
    case TargetType.DOMAIN:
      return 'Domain';
      break;

    case TargetType.SUB_DOMAIN:
      return 'Sub Domain';
      break;

    case TargetType.IP_ADDRESS:
      return 'IP Address';
      break;

    case TargetType.DOMAIN_SUB_DOMAIN:
      return '';
      break;

    default:
      break;
  }
};
