import { makeStyles } from '@material-ui/core/styles';
import { Column, Table } from 'components/Table';
import { AgencyStatsDTO, AgentDTO } from 'dtos/agent';
import { useCurrentSubAgenciesStats } from 'queries/useAgency';
import React, { useMemo } from 'react';
import { isAgentAGlobalAdmin } from 'telivy-selectors';

import { DeleteAgency } from './DeleteAgency';
import { InviteEditAgency } from './InviteEditAgency';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  title: {
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

interface Props {
  agent: AgentDTO;
}

export const AgenciesTable = ({ agent }: Props) => {
  const classes = useStyles();
  const { data, isLoading } = useCurrentSubAgenciesStats();
  const isGlobalAdmin = isAgentAGlobalAdmin(agent);

  const columns: Array<Column<AgencyStatsDTO, null>> = useMemo(() => {
    return [
      {
        title: 'Name',
        render: (a) => a.name,
      },
      {
        title: 'Domain',
        render: (a) => a.domain,
      },
      {
        title: 'Users',
        render: (a) => a.totalUsers,
      },
      {
        title: 'Risk Assessments',
        render: (a) => a.totalRiskAssessments,
      },
      {
        title: 'Total Assets',
        render: (a) => a.totalActiveLightScanAssetsCount,
      },
      {
        title: 'Actions',
        render: (row) => {
          if (!isGlobalAdmin) return null;

          return (
            <div className='flex gap-2'>
              <InviteEditAgency agency={row} />
              <DeleteAgency agency={row} />
            </div>
          );
        },
      },
    ];
  }, [isGlobalAdmin]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h1 className={classes.title}>Accounts</h1>
        {isGlobalAdmin && <InviteEditAgency />}
      </div>
      <Table<AgencyStatsDTO, null> columns={columns} rowKey={(a) => a.id} data={data} loading={isLoading} />
    </div>
  );
};
