import { AgencyType, AgentDTO, AgentRole, BaseAgentDTO } from 'dtos/agent';
import { ApplicationDTO, ApplicationInsuranceLine } from 'dtos/application';

export const isUrlSaluswall = window.location.toString().includes('saluswall');
export const getWebsiteName = (agent?: AgentDTO): string => {
  const portalName = agent?.masterAgency?.name || (agent?.agency?.isMasterAgency ? agent?.agency?.name : undefined);
  if (portalName) return portalName;

  return isUrlSaluswall ? 'SalusWall' : 'Telivy';
};

// The purpose of this file is to keep all the functions that extract the data from the DTO models in one place.
export const isAgentAnAdmin = (agent?: AgentDTO): boolean => agent?.role === AgentRole.TELIVY_ADMIN;
export const isAgentAnAgencyAdmin = (agent?: AgentDTO): boolean => agent?.role === AgentRole.AGENCY_ADMIN;
export const isAgentAnAnyAdmin = (agent?: AgentDTO): boolean => isAgentAnAdmin(agent) || isAgentAnAgencyAdmin(agent);
export const canSeeBilling = (agent?: AgentDTO): boolean =>
  isAgentAnAnyAdmin(agent) &&
  !agent?.agency?.hideBilling &&
  (agent?.masterAgency ? !agent?.masterAgency?.hideBillingForChildAgencies : true);

export const isAgentAGlobalAdmin = (agent?: AgentDTO): boolean =>
  agent?.role === AgentRole.AGENCY_ADMIN && !!agent?.agency?.isMasterAgency;

export const isSecurityAgency = (agent?: AgentDTO | BaseAgentDTO): boolean =>
  agent?.agency?.agencyType === AgencyType.SECURITY_DASHBOARD;

export const SECURITY_VERSION = 'vSecurity';
export const isSecurityApplication = (application?: ApplicationDTO): boolean =>
  application?.applicationVersion?.applicationVersion === SECURITY_VERSION;

export const isScanApplication = (application?: ApplicationDTO): boolean =>
  application?.applicationVersion?.applicationVersion === SECURITY_VERSION &&
  (application?.isDeepScan || application?.isLightScan);

export const isCyberInsuranceLine = (application: ApplicationDTO): boolean =>
  application.insuranceLine === ApplicationInsuranceLine.CYBER;

export const isWesternInsuranceAgent = (agent?: AgentDTO | BaseAgentDTO): boolean =>
  agent?.email === 'hugh@western-insurance.net';

export const isTopologyEnabled = (agent?: AgentDTO): boolean => !!agent?.agency?.enableTopology;

export const isNodewareEnabled = (agent?: AgentDTO): boolean => !!agent?.agency?.nodewareApiKey;
